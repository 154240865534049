// @flow
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import Sidebar from '../components/Sidebar';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { useSiteMetadata, useCategoriesList } from '../hooks';

const CategoriesListTemplate = () => {
  const {
    url: siteUrl,
    title: siteTitle,
    subtitle: siteSubtitle,
    keywords: siteKeywords,
    author: siteAuthor,
    twitter: siteTwitter
  } = useSiteMetadata();

  const categories = useCategoriesList();

  return (
    <Layout title={`Categories | ${siteTitle}`} description={siteSubtitle} keywords={siteKeywords}
            author={siteAuthor.name} image={`${siteUrl}${siteAuthor.photo}`} twitter={siteTwitter}>
      <Sidebar />
      <Page title="Categories">
        <ul>
          {categories.map((category) => (
            <li key={category.fieldValue}>
              <Link to={`/articles/categories/${kebabCase(category.fieldValue)}/`}>
                {category.fieldValue} ({category.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
    </Layout>
  );
};

export default CategoriesListTemplate;